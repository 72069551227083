
import React, {useContext, useEffect, useState, useRef} from 'react';
import {Redirect} from 'react-router-dom';
//import * as Realm from 'realm-web';

import {Line} from 'react-chartjs-2';
import 'chartjs-adapter-luxon';
import { StreamingPlugin, RealTimeScale } from '@robloche/chartjs-plugin-streaming';
import moment from 'moment';
import Chart from 'chart.js/auto';

import { 
    Button,
    Grid,
    Divider,
    Typography,
    TextField,
    MenuItem,
    Accordion,
    AccordionSummary,
    AccordionDetails
  } from '@material-ui/core';


import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { spacing } from '@material-ui/system';
import SocketIOContext, {initializeSocket} from '../../context/SocketIOContext';

import { green, blue, orange, yellow, blueGrey, lightBlue } from '@material-ui/core/colors';
import {
  Activity,
  Info,
  Smile,
  Video,
  UserPlus,
  Film
} from 'react-feather';
import * as RouteNames from '../../common/routeNames';

import Actions from './components/Actions';
import Stats from './components/Stats';
import useStyles from './styles';
import { useUserState, refreshToken} from '../../context/UserContext';
import * as AppRoutes from '../../common/routeNames';
import { useQuery, QueryClient, QueryClientProvider } from 'react-query';
import { getAllDevices, getAllOrgs, getDeviceStatus, getDeviceData } from '../../common/apiGetUtilities';
import { DeviceProps, emptyDeviceState } from '../../common/entityUtilities';
import { DeviceDetailsDialog } from '../../dialogs/DeviceDetailsDialog';
import { MonitorHeart } from '@mui/icons-material';
const currentDate = new Date();


const chartOptions = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      xAxes: [
        {
          type: "realtime",
          distribution: "linear",
          realtime: {
            onRefresh: function(chart) {
              chart.data.datasets[0].data.push({
                x: moment(),
                y: Math.random()
              });
            },
            delay: 3000,
            time: {
              displayFormat: "h:mm"
            }
          },
          ticks: {
            displayFormats: 1,
            maxRotation: 0,
            minRotation: 0,
            stepSize: 1,
            maxTicksLimit: 30,
            minUnit: "second",
            source: "auto",
            autoSkip: true,
            callback: function(value) {
              return moment(value, "HH:mm:ss").format("mm:ss");
            }
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 1
          }
        }
      ]
    }
  };

Chart.register(StreamingPlugin, RealTimeScale);

function Dashboard(props) {


    const [expandLiveStreamAccordion, setExpandedLiveStream] = useState(false);
    const [realmEvents, setRealmEvents] = useState([]);
    const [realmUser, setRealmUser] = useState();
    const classes = useStyles();
    const [redirect, setRedirect] = useState(null);
    const [kiwiUser, setActiveUser] = useState(useUserState());
    const [devices, _setDevices] = useState([]);
    const [myChart, setMyChart] = useState(null);
    const myChartRef = useRef(myChart);
    const streamingDeviceRef = useRef(null);
    const selectedDeviceRef = useRef(null);
    const [deviceDetailsVisible, setDeviceDetailsVisible] = useState(false);
    const [selectedDevice, setSelectedDevice] = useState(null);
    const [deviceStatusMessage, setDeviceStatusMessage] = useState('');
    const [selectedDeviceStatusMessage, setSelectedDeviceStatusMessage] = useState('');
    const [selectedStreamingDevice, setStreamingDevice] = useState(null);
    const [textFieldShrink, setTextfieldShrink] = useState(true);
    const [authLevel, setAuthLevel] = useState(0);
    const [accelerationMin, setAccelMin] = useState(-1.0);
    const [accelerationMax, setAccelMax] = useState(1.0);
    const registerPage = AppRoutes.USER_SETTINGS;
    const socket = initializeSocket();
    const devicesRef = useRef(devices);
    const componentWillUnmount = useRef(false);
    const [allOrgs, setAllOrgs] = useState([]);
    const [timerId, setTimerId] = useState(null);

    useEffect( ()=> {
        console.log('CpapUser:', kiwiUser);
        setAuthLevel(kiwiUser.authLevel)
    }, []);

    useEffect ( ()=>{
        return ()=> { componentWillUnmount.current = true;}
    },[]);

    useEffect ( ()=>{
        const setRefreshTimer = async () => {
            // bearerToken for authentication expires in about one hour
            if (kiwiUser){
                const id = setInterval( async()=>{
                    await refreshToken(kiwiUser);
                }, 1000 * 60 * 30 ); // REFRESH every 30 MINUTES
                setTimerId(id);

            }
        };

        setRefreshTimer();
        
        return () => {
            // this now gets called when the component unmounts
            console.log('CLEARING interval timer');
            clearInterval(timerId);
        };
    }, [kiwiUser]);

    useEffect( ()=>{
        const getOrgs = async () => {
            // get all the orgs
            console.log('Get Orgs for Active user:', kiwiUser);
            const orgs = await getAllOrgs(kiwiUser);
            setAllOrgs(orgs);
        };
        
        getOrgs();

        return () => {
            // this now gets called when the component unmounts
        };
    }, [kiwiUser]);
    useEffect ( ()=> {
        return ()=>{
            if (componentWillUnmount.current){
                console.log('Component unmounting');
                myChartRef.current = null;
            }
        }
    }, []);
    // the following can be used to WATCH a collection. 
    // collection cannot be a TimeSeries HOWEVER!
    // July 2023
    // useEffect( ()=> {

    //     const realmLogin = async()=> {
    //          const realmApp = new Realm.App( {id: "application-0-ocbre"});
    //         // authenticate anonymously
    //         const user = await realmApp.logIn(Realm.Credentials.anonymous());
    //         setRealmUser(user);

    //         // connect to the database
    //         const mongodb = realmApp.currentUser.mongoClient('mongodb-atlas');
    //         const collection = mongodb.db('test').collection('events');
    //         // Everytime a change happens in the stream, add it to the list of events
    //         for  await (const  change  of  collection.watch({
    //             // filter:{
    //             //     operationType: 'insert',
    //             //     'fullDocument.deviceId': '001'
    //             // }
    //         })) {
    //             const {documentKey, fullDocument} = change;
    //             console.log('New event added at:', fullDocument.timestamp);
    //             break; // exit async iterator
    //             //setRealmEvents(events  => [...events, change]);
    //         }
    //     }
    //     // call realm login
    //     realmLogin();
    // }, []);

    useEffect( ()=>{
        console.log('Device details visible:', deviceDetailsVisible);
    }, [deviceDetailsVisible]);
    

    useEffect ( ()=>{
        myChartRef.current = null;
        console.log('Setting myChartRef to null');
    },[]);
    useEffect(()=>{
        socket.on("message", handleSocketMessage);
    },[]);

    const handleDeviceDetailsClose = ()=> {
        setDeviceDetailsVisible(false);
        setSelectedDevice(null);
    }

    const setDevices = (devs) => {
        devicesRef.current = devs;
        console.log('Set devices to:', devs);
        _setDevices(devs);
        if (devs?.length>0 && streamingDeviceRef.current != null){
            setStreamingDevice(devs[0]);
            //streamingDeviceRef.current = devs[0];
        }
    };

    const handleEmitMessage = (message) => {
        console.log('Emit message from socket:', message);
    }

    const handlePayloadMessage = async (message) => {
        //console.log('Device Payload message:');
        // handle it
        const deviceId = message['deviceId'];
        const accX = JSON.parse(message ['accX']);
        const accY = JSON.parse(message ['accY']);
        const accZ = JSON.parse(message ['accZ']);
        
        let dataX = []
        let dataY = []
        let dataZ = []
        accX.forEach( (element) => {
            dataX.push({
                x: new Date(element.x),
                y: element.y
            })
        });
        accY.forEach( (element)=> {
            dataY.push({
                x: new Date(element.x),
                y: element.y
            })
        })
        accZ.forEach( (element) => {
            dataZ.push({
                x: new Date(element.x),
                y: element.y
            })
        });
        

        if(myChartRef && myChartRef.current){
            console.log('pushing data 1 of 10:', dataX[0]);
            myChartRef.current.data.datasets[0].data.push(...dataX);
            myChartRef.current.data.datasets[1].data.push(...dataY);
            //myChartRef.current.data.datasets[2].data.push(...dataZ);
            myChartRef.current.update('quiet');
            console.log('Updated chart');
        }
    }

    const handleSocketMessage = (message)=>{

        let messageType = 'devicestatus';
        // 'devicestatus' messageType indicates the device status has changed from ON/OFF or vice versa
        //
        // other messageType include: 
        // 'emit' when the kiwiapp wants to tell a specific raspi device to gather data and send it
        // using the endpoint <cpap_url>/devicestatus/emitdata/:deviceid
        // 'devicepayload', when the raspi device has successfully gathered uptodate payload data
        // and written it to the server
        // 'devicecommand', when the app wants to send a command to the raspi device

        if (message?.messageType) {
            messageType = message?.messageType;
        }

        if (messageType == 'emit'){
            console.log('EMIT message:', message);
            handleEmitMessage(message);
            return;
        }
        else if (messageType == 'devicepayload'){
            console.log('device payload from socket for:', message?.deviceId);
            //console.log('selected streaming device:', streamingDeviceRef?.current);
            if (message.deviceId == streamingDeviceRef?.current?._id){
                handlePayloadMessage(message);
            }
            return;
        }
        else if (messageType == 'devicecommand'){
            console.log('devicecommand message:', message);
            return;
        }

        // Update devices status and display status message for selected device
        // selected device in dashboard is referred by streamingDeviceRef?.current?._id
        // if the user clicks on one of the devices displayed in the dashboard for 'Details'
        // you can refer to the selectedDevice variable for that

        const deviceId = message?.deviceId;
        const status = message?.status;

        //console.log('Socket Message for device:', deviceId);
        //console.log('Message:', message);
        let notes = message?.notes;
        let localdeviceStatusMessage = JSON.parse(notes);
        // console.log('Device status message:', localdeviceStatusMessage?.message);
        // console.log('selectedDevice:', selectedDeviceRef.current);
        // console.log('currentstreaming device:', streamingDeviceRef?.current);
        if (localdeviceStatusMessage?.message){
            if (deviceId == streamingDeviceRef?.current?._id){
                    setDeviceStatusMessage(localdeviceStatusMessage.message);
            }
            if (deviceId == selectedDeviceRef.current?._id){
                setSelectedDeviceStatusMessage(localdeviceStatusMessage.message);
            }
        }
        // console.log('Device status:', status);
        // console.log('User auth level:', authLevel);
        // console.log('Kiwi user:', kiwiUser);
        // console.log('DEVICES:', devicesRef.current.length);
        let localDevices = [];
        for(let i=0; i<devicesRef.current.length;i++){
            if(devicesRef.current[i]?._id == deviceId){
                devicesRef.current[i].status = status;
            }
            localDevices.push(devicesRef.current[i]);
        }
        //console.log('Updated Devices:', devicesRef.current);
        setDevices(localDevices);
    };

    const getRemoteData = async (user, devs) => {
        for(let i=0; i<devs.length; i++){
            let device = devs[i];
            //console.log('Getting status for Deviceid:', device?._id);
            const result = await getDeviceStatus(user, device?._id);
            // devices_status.push(result);
            devs[i].status = result.status

        }
        
        console.log('Devices Status:', devs);
        return devs;
        
    };


    const getRealData = async () => {
        console.log('Getting real data for chart:', myChart);
        const now = new Date(Date.now())
        const tensecs = new Date(now-10*1000)
        const enddate = now.toISOString()
        const startdate = tensecs.toISOString()
        const res = await getDeviceData(kiwiUser, "001", startdate, enddate);
        //console.log('Result:', res);
        // return three arrays -- accX, accY, and accY values
        let accX = []
        let accY = []
        let accZ = []
        res.forEach( (element) => {
            accX.push({
                x: new Date(element.timestamp),
                y: element.accX
            })
            accY.push({
                x: new Date(element.timestamp),
                y: element.accY
            })
            accZ.push({
                x: new Date(element.timestamp),
                y: element.accZ
            })
        })
        console.log('accX:', accX);
        return [accX, accY, accZ];

    }
    useEffect( ()=> {
        const localFn = async()=>{
            console.log('Getting all devices');
            console.log('Kiwi user:', kiwiUser);
            let devs = await getAllDevices(kiwiUser);
            devs = await getRemoteData(kiwiUser, devs);
            setDevices(devs);
            console.log('Devices fetched:', devs);
            if (devs?.length > 0 && streamingDeviceRef.current != null) {
                setStreamingDevice(devs[0]);
                //streamingDeviceRef.current = devs[0];
            }
        }
        localFn();
    }, []);

    const handleChartRange = (value)=>{
        console.log('Chart range changed:', value);
    };

    const handleStartDevice = (id) => {
        setSelectedDeviceStatusMessage('Starting device...');
    }
    const handleStopDevice = (id) => {
        setSelectedDeviceStatusMessage('Stopping device...');
    }


    const handleLiveStreamAccordion = ()=> {
        setExpandedLiveStream(!expandLiveStreamAccordion);
    };

    // const {isLoading, fetchedData, isError, error, isFetching} = useQuery(
    //     'device-status', 
    //     getRemoteData(kiwiUser, devices),
    //     {
    //         refetchInterval: 1000
    //         // refetchIntervalInBackground: true
    //     }

        
    // );



    if (authLevel >= 0){
        return (
            <React.Fragment>
                <SocketIOContext.Provider value={{socket}}>
                <div className={classes.paper}>
                <Grid container spacing={4} className={classes.paper}
                >
                    
                    <Grid item md={6} sm={6} xs={6}
                    >
                        <Typography
                            display="inline"
                            variant="h5"
                            color='secondary'
                        >
                            Welcome back, {kiwiUser.localDisplayName}
                        </Typography>
                    </Grid>

                    <Grid item md={6} sm={6} xs={6}>
                    <Typography
                        display="inline"
                        ml={2}
                        variant="body2"
                    >
                        {new Date(currentDate).toDateString()}
                    </Typography>
                    </Grid>

                    {devicesRef.current && 
                        devicesRef.current.map(device=> {
                            const idToUse = device?._id;
                        return(
                            <Grid
                            item
                            key={idToUse}
                            lg={3}
                            md={6}
                            sm={6}
                            xl
                            xs={12}>

                                <Stats
                                color={device?.status== 1 ? green[500]:orange[500]}
                                daysBack = {30}
                                handler = {()=> {
                                    console.log('Clicked on device:', device);
                                    setSelectedDevice(device);
                                    selectedDeviceRef.current = device;
                                    setDeviceDetailsVisible(true);
                                    setSelectedDeviceStatusMessage('...');
                                }}
                                id={device?._id}
                                icon={Activity}
                                title={device?.deviceId}
                                status={device?.status}
                                />

                            </Grid>
                        )
                        })
                    }
                    
                    <Divider my={12} />

                    <Grid item  xs={12} sm={12} md={12}>
                    <Accordion expanded={expandLiveStreamAccordion}>
                        <AccordionSummary
                            aria-controls="panel1a-content"
                            expandIcon={<ExpandMoreIcon color="primary"/>}
                            IconButtonProps={{
                            onClick:handleLiveStreamAccordion
                            }}
                            id="subject-history-header"
                        >
                            <Typography
                                color="secondary"
                                variant="h6"
                            >
                                Live Stream
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                   
                        <Grid container item  xs={12} sm={12} md={12} spacing={4}>
                        <Grid item xs={6} md={6}>
                        <TextField 
                            id="streamingDevice"
                            onFocus={()=> 
                                {console.log('onfocus');
                                setTextfieldShrink(true);}
                            }
                            onBlur={(e)=> setTextfieldShrink(e.target.value)}
                            InputLabelProps={{shrink:textFieldShrink}}
                            select
                            variant='outlined'
                            color="primary"
                            label="Kiwi Device"
                            onChange={(event) => {
                                const selectedItem = devicesRef?.current?.find(item=>item.deviceId==event.target.value);
                                if (selectedItem?._id != selectedStreamingDevice?._id){
                                    setDeviceStatusMessage('');
                                }
                                setStreamingDevice(selectedItem);
                                streamingDeviceRef.current = selectedItem;
                                console.log('device selected:',selectedItem);} }
                            style={{ width: 300 }}
                            // value={selectedStreamingDevice && selectedStreamingDevice?.deviceId}
                            value = {streamingDeviceRef && streamingDeviceRef.current?.deviceId}
                        >
                            {devicesRef.current && devicesRef.current.map((device) => (
                                <MenuItem
                                    key={device._id}
                                    value={device.deviceId}
                                >
                                    {device.deviceId}
                                </MenuItem>
                            ))}
                        </TextField>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Line 
                            data={{
                                datasets: [
                                {
                                    label: "Acc (mean)",
                                    borderColor: "rgb(54,162, 235)",
                                    backgroundColor: "rgba(54,162, 235, 0.5)",
                                    cubicInterpolationMode: 'monotone',
                                    borderDash:[4,4],
                                    data: []
                                },
                                {
                                    label: "Acc (sd)",
                                    borderColor: "rgb(162,54, 235)",
                                    backgroundColor: "rgba(162,54, 235, 0.5)",
                                    cubicInterpolationMode: 'monotone',
                                    data: []
                                },
                                // {
                                //     label: "Acc Z",
                                //     borderColor: "rgb(235,162,54)",
                                //     backgroundColor: "rgba(235,162,54, 0.5)",
                                //     cubicInterpolationMode: 'monotone',
                                //     data: []
                                // }
                                ],
                            }}
                            options={{
                                scales: {
                                x: {
                                    type: "realtime",
                                    realtime: {
                                        onRefresh: async (chart) => {

                                            if(myChartRef.current==null){
                                                console.log('Setting myChart to ', chart);
                                                myChartRef.current = chart;
                                                setMyChart(chart);
                                                console.log('devices:', devicesRef.current.length);
                                                if(devicesRef?.current?.length > 0){
                                                    console.log('setting streaming device:', devicesRef?.current[0]);
                                                    setStreamingDevice(devicesRef.current[0]);
                                                }
                                            }
                                            // for PULL model, use the one below
                                            // this is when you are going to POLL a server
                                            // at a frequency
                                            // getRealData().then( values=>{
                                                
                                                
                                            //     chart.data.datasets[0].data.push(...values[0]);
                                            //     chart.data.datasets[1].data.push(...values[1]);
                                            //     chart.data.datasets[2].data.push(...values[2]);
                                            //     chart.update('quiet');
                                            // })
                                            
                                        
                                            

                                            // chart.data.datasets.forEach((dataset,index)=>{
                                            //     dataset.data.push({
                                            //         x: Date.now(),
                                            //         y: Math.random()
                                            //     });
                                            // }
                                            
                                        },
                                        delay: 10000,
                                        frameRate:30,
                                        //refresh:10000, // onRefresh callback frequency
                                    },
                                    },
                                y: {
                                    title: {
                                    display: true,
                                    text: "Acceleration",
                                    },
                                    min:accelerationMin, 
                                    max:accelerationMax},
                                },
                            }}
                            />
                            {/* <Button variant="outlined" onClick={getRealData}>
                                Get Data
                            </Button> */}
                        </Grid>

                        <Grid item xs={6} md={6}>
                            <TextField
                                id='chartRange'
                                InputLabelProps = {{readOnly:false, shrink:true}}
                                label='Chart Range (upper)'
                                color='primary'
                                type='number'
                                value={accelerationMax}
                                onChange={(event)=>{
                                setAccelMax(event.target.value);}}
                            >
                                
                            </TextField>
                        </Grid>
                        <Grid item xs={6} md={6}>
                            <TextField
                                id='chartRangeLower'
                                InputLabelProps = {{readOnly:false, shrink:true}}
                                label='Chart Range (lower)'
                                color='primary'
                                type='number'
                                value={accelerationMin}
                                onChange={(event)=>{
                                setAccelMin(event.target.value);}}
                            >
                                
                            </TextField>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Typography
                                display="inline"
                                ml={2}
                                color="secondary"
                                variant="body2"
                            >
                                {/* Currently streaming: {selectedStreamingDevice?.deviceId} */}
                                Currently streaming: {streamingDeviceRef.current?.deviceId}
                            </Typography>
                        </Grid> 
                        <Grid item md={4} sm={4} xs={4}>
                            <Typography
                                display="inline"
                                ml={2}
                                color="secondary"
                                variant="body2"
                            >
                                Streaming device status:
                            </Typography>
                        </Grid>

                        <Grid item md={8} sm={8} xs={8}>
                            <Typography
                                display="inline"
                                ml={2}
                                variant="body2"
                            >
                                {deviceStatusMessage}
                            </Typography>
                        </Grid>
                    
                        </Grid>
                        </AccordionDetails>
                    </Accordion>

                    </Grid>
                    <DeviceDetailsDialog
                        allOrgs={allOrgs}
                        isOpen={deviceDetailsVisible}
                        device={selectedDevice}
                        deviceStatusMessage={selectedDeviceStatusMessage}
                        handleClose = {handleDeviceDetailsClose}
                        handleStart = {handleStartDevice}
                        handleStop = {handleStopDevice}
                    />
                </Grid>

                     
                
                
                </div>
                </SocketIOContext.Provider>
            </React.Fragment>
          );
    }
    else { 
        return <Redirect to={registerPage}/>;
    }
}

export default Dashboard;